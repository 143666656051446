import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { Response } from 'src/app/models/response.model';
import { environment } from 'src/environments/environment';

import { User } from '../user/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  notifier = new EventEmitter()
  private socket: SocketIOClient.Socket;
  private api = environment.host
  constructor(private http: HttpClient) {
    this.socket = io(environment.host);
  }
  
  getItem(){
    return localStorage.getItem('token')
  }
  
  auth(value: User): Observable<Response<User>>{ 
    return this.http.patch<Response<User>>(`${this.api}/auth`, value)
  }

  block(){ 
    return this.http.get(`${this.api}/block`)
  }

  verificarSessao(){
    return localStorage.getItem('token') != null
  }

  isLogged(){
    return localStorage.getItem('name') != null
  }

  notify(){
    this.notifier.emit(true)
  }

  listeningMenuProfile(){
    return new Observable(observer => {
      this.socket.on('menu', (data: any) => {
        observer.next(data);
      })
      return () => {
        this.socket.disconnect();
      }
    });
  }

  getIdUserLogged(){
    return Number(localStorage.getItem('id'))
  }
  getIdProfileLogged(){
    return Number(localStorage.getItem('profile'))
  }
  
}
