import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Article } from 'src/app/pages/article/article.model';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-article-price-table',
  templateUrl: './article-price-table.component.html',
  styleUrls: ['./article-price-table.component.css']
})
export class ArticlePriceTableComponent implements OnInit {
  @Input() listBkp: any[] = []
  @Input() list: Article[]
  @Input() showBtn = true
  constructor(private service: SharedService,
              private toastr: ToastrService
    ) { }

  ngOnInit() {
  }

  onChange(index: number){
    
    this.listBkp[index].changed = false
    if( this.list[index].withoutComission !== this.listBkp[index].withoutComission) {
      this.listBkp[index].changed = true
    }

    if(this.list[index].pvp !== this.listBkp[index].pvp){
      this.listBkp[index].changed = true
    }
  
    
  }

  enableSave(){
    return this.listBkp.some(l => l.changed)
  }

  updateOne(item: Article){
    this.service.configurarApiUrl('article-price')
    this.service.update(item.id, item)
                .subscribe((resp: any)=>{
                  this.toastr.success(resp.msg, 'Muito bem!');
                })
  }

  updateMultiple(){
    const onlyChanged = this.listBkp.filter(l => l.changed)
    this.service.configurarApiUrl('article-price')
    this.service.bulkUpdate(onlyChanged)
                .subscribe((resp: any)=>{
                  this.toastr.success(resp.msg, 'Muito bem!');
                })

  }

  

}
