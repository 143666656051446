import { CommonModule, registerLocaleData } from "@angular/common";
import { LOCALE_ID, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "../components/input/input.component";
import { ClickOutsideDirective } from "../directives/click-outside.directive";
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "../security/auth.interceptor";
import { SnackbarComponent } from "../components/message/snackbar/snackbar.component";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxCurrencyModule } from "ngx-currency";
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { CustomCurrencyMaskConfig } from "./CustomCurrencyMaskConfig";
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
registerLocaleData(localeDe, 'de-DE', localeDeExtra)

import { ToastrModule } from 'ngx-toastr';
import { ArticlePriceTableComponent } from "../pages/price/price-form/article-price-table/article-price-table.component";
import { NgbAlertModule, NgbModule, NgbPaginationModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
    declarations: [
        ClickOutsideDirective,
        InputComponent,
        SnackbarComponent,
        ArticlePriceTableComponent,
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        AngularDualListBoxModule,
        NgxMaskModule.forRoot(),
        NgxCurrencyModule.forRoot(CustomCurrencyMaskConfig),
        BsDatepickerModule.forRoot(),
        ModalModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            progressBar: true
        }),
     
        
    ],
    exports: [
        ClickOutsideDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        InputComponent,
        NgSelectModule,
        AngularDualListBoxModule,
        SnackbarComponent,
        NgxMaskModule,
        NgxCurrencyModule,
        BsDatepickerModule,
        ModalModule,
        ToastrModule,
        ArticlePriceTableComponent,
       
      
    ]
})
export class SharedModule{
    static forRoot(): ModuleWithProviders{
        return {
            ngModule: SharedModule,
            providers: [
                BsDatepickerConfig,
                BsModalService,
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
                {
                    provide: LOCALE_ID,
                    useValue: 'de-DE'
                },
            ]
        }
    }
}
