import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  getNome(){
    return localStorage.getItem('name')
  }

  getPerfil(){
    return localStorage.getItem('group')
  }

  getEmail(){
    return localStorage.getItem('email')
  }

  sair(){
    localStorage.clear()
    this.router.navigate(['/'], {replaceUrl: true})
  }


}
