import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormService } from '../../order/form.service';
import { TokenService } from '../token.service';
import { MessageService } from '../../../components/message/message.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent implements OnInit {
  public onClose: Subject<boolean>;
  formGroupSenha: FormGroup
  formGroupVerificationCode: FormGroup
  formGroupEmail: FormGroup
  hidePass = true
  hideConfirmPass = true
  showPass = false
  showCode = false
  responseEmail: any
  @ViewChild("campo2", { static: false }) campo2: ElementRef;  
  constructor(private _bsModalRef: BsModalRef,
              private _formService: FormService,
              private _tokenService: TokenService,
              private _msgService: MessageService,
              private _userService: UserService) { }
  
  ngOnInit() {
    this.onClose = new Subject();
    this.formGroupSenha = new FormGroup({
      password: new FormControl(null, {validators: [Validators.required, Validators.minLength(6)]}),
      confirmPassword: new FormControl(null, {validators: [Validators.required,, Validators.minLength(6)]})
    },{
      validators: this.validateConfirmPassword.bind(this)
    })
    
    this.formGroupVerificationCode = new FormGroup({
      campo1: new FormControl(),
      campo2: new FormControl(),
      campo3: new FormControl(),
      campo4: new FormControl(),
      campo5: new FormControl(),
      campo6: new FormControl()
    })

    this.formGroupEmail = new FormGroup({
      email: new FormControl(null, {validators: [Validators.required]})
    })
  }
  
  validateConfirmPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  
  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }
  
  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
  
  hideShowPassword(option = 0){
    if(option === 0)
    this.hidePass = !this.hidePass
    else{
      this.hideConfirmPass = !this.hideConfirmPass
    }  
  }
  
  selecionar(campo: string){
    this[campo].nativeElement.focus()
  }
  
  informHaveCode(){
    this.showCode = true
  }
  @HostListener('window:keydown',['$event'])
  onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 67)
      console.log('CTRL + C');
    
    if(($event.ctrlKey || $event.metaKey) && $event.keyCode == 86){
      const clipboardEvent: ClipboardEvent = new ClipboardEvent('paste');
      console.log('CTRL +  V');
      this.pasteEvent(clipboardEvent)
    }
  }

  pasteEvent(event: ClipboardEvent){  
    const isIEorEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    
    if (isIEorEdge) {
      const data = event.clipboardData || window['clipboardData'];
      const clipboardData = data.getData('text');
      
      this.setVerificationCode(clipboardData)
    } else {
      navigator['clipboard'].readText().then((data) => {
        console.log('data',data);
        
          this.setVerificationCode(data)
      });
    }
  }

  setVerificationCode(data: string){
    const mask = /^\d{3}-\d{3}$/
    const mask2 = /^\d{3}\d{3}$/
    if( data.match(mask) || mask2){
      const code = data.split('-')
      let idx = 0
      const obj = {}
      for (var v of `${code[0]}${code[1]}`) {
        idx++;
        obj[`campo${idx}`] = v
      }
      console.log('campo', obj);
      
      this.formGroupVerificationCode.patchValue(obj)
      
      this.validarToken()        
      
    }
  }

  validarToken(){
    this._formService.waitingDialog('Validando token', 'Aguarde enquanto validamos o token informado')
    const {email} = this.formGroupEmail.value
    const arrToken = Object.keys(this.formGroupVerificationCode.value).map(f =>{
        return this.formGroupVerificationCode.value[f]
    })
    arrToken.splice(3,0,'-')
    const token = arrToken.join('')
    this._tokenService
        .validateToken(email, token)
        .subscribe((response: any)=>{
          if( response.status ){
            this.showPass = response.status
            this._formService.closeDialog()
          }else{
            this._formService.setMessage({title: 'Ops', message: response.msg, icon: 'error'})
          }
        })
  }

  recuperarEmail(){
    this.responseEmail = null
    this._tokenService
        .generate(this.formGroupEmail.value.email)
        .subscribe((response: any)=>{
            this.responseEmail = response
            this._msgService.notify({message: response.msg, status: response.status})
        }, err => {
          
          const msg = err.error.error.error
          this.responseEmail = {
            msg, status: false
          }
          this._msgService.notify({message: msg, status: false})
        })

  }

  updatePassword(){
      delete this.formGroupSenha.value.confirmPassword
      const {email} = this.formGroupEmail.value
      
      this._userService
          .updateEmail(email, this.formGroupSenha.value)
          .subscribe((response: any)=>{
              this._formService
                  .setMessage({title: 'Muito bem!', message: response.msg, icon: 'success'})
                  .then(() => {
                    this.onConfirm()
                  })
                  
          })

  }
  
  
}
