import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MessageService } from 'src/app/components/message/message.service';
import { FormService } from '../../order/form.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  public onClose: Subject<boolean>;
  formGroupSenha: FormGroup
  hidePass = true
  hideConfirmPass = true
  responseEmail: any
  threeSeconds: number = 3000
  constructor(private _bsModalRef: BsModalRef,
              private _userService: UserService,
              private _msgService: MessageService,
              private _formService: FormService  ) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.formGroupSenha = new FormGroup({
      password: new FormControl(null, {validators: [Validators.required, Validators.minLength(6)]}),
      confirmPassword: new FormControl(null, {validators: [Validators.required,, Validators.minLength(6)]}),
      changePassword: new FormControl(false)
    },{
      validators: this.validateConfirmPassword.bind(this)
    })
  }

  validateConfirmPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }
  
  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  updatePassword(){
    this._formService.waitingDialog('Validando token', 'Aguarde enquanto validamos o token informado')
    this.responseEmail = null
    delete this.formGroupSenha.value.confirmPassword
    const id = localStorage.getItem('id')
    this._userService
        .update(Number(id), this.formGroupSenha.value)
        .subscribe((response: any)=>{
          
          this.responseEmail = response
          this._msgService.notify({message: response.msg, status: true})
          this._formService
                  .setMessage({title: 'Muito bem!', message: response.msg, icon: 'success'})
                  .then(() => {
                    this.onConfirm()
                  })
        })

  }

  hideShowPassword(option = 0){
    if(option === 0)
    this.hidePass = !this.hidePass
    else{
      this.hideConfirmPass = !this.hideConfirmPass
    }  
  }

}
