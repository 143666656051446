import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private api = environment.host
  constructor(private http: HttpClient) {
    this.api = `${this.api}/token`
  }

  generate(email: string){
    return this.http.patch(`${this.api}/generate`, {email})
  }

  validateToken(email: string, token: string){
    return this.http.patch(`${this.api}/validate`, {email, token})
  }
}
