import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from './components/menu/menu.model';
import { LoginService } from './pages/login/login.service';
import { PermissionProfileService } from './pages/profile/permission-profile.service';
import { ProfileService } from './pages/profile/profile.service';
import { Permission } from './shared/permission.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'projeto-alpha-front';
  constructor(private loginService: LoginService,
              private profileService: ProfileService,
              private router: Router
    ){
    this.listeningMenuProfile()

    if(!this.isLogged()){
      this.router.navigate(['/'])
    }
  }

  isLogged(){ 
    return this.loginService.isLogged()
  }



  listeningMenuProfile(){
    this.loginService
        .listeningMenuProfile()
        .subscribe((response: any)=>{
          const {profile} = response
          console.log('response',response);
          
          if( Number(profile) === Number( localStorage.getItem('profile') ) )
            this.setMenus()
        })     
  }

 

  setMenus(){
    this.profileService
      .getMenu()
      .subscribe((menus: Menu[])=>{
        localStorage.setItem('menu', JSON.stringify(menus))
        this.loginService.notify()
      })
  }
}
