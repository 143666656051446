import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../pages/login/login.service';


export class AuthInterceptor implements HttpInterceptor{
    constructor(private injector: Injector){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        const loginService = this.injector.get(LoginService)
        if(loginService.verificarSessao()){
            const authRequest = request.clone({
                setHeaders: {'x-access-token': localStorage.getItem('token') }
            })
            return next.handle( authRequest )
        }
        return next.handle( request )
    }
}
