import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu } from 'src/app/components/menu/menu.model';
import { Response } from 'src/app/models/response.model';
import { environment } from 'src/environments/environment';
import { ProfileList } from './profile-list.model';
import { Profile } from './profile.model';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private api = ""
  private apiMenu = "";
  constructor(private http: HttpClient) {
    this.api = `${environment.host}/api/profile`
    this.apiMenu = `${environment.host}/api/profile-menu`
  }

  findAll(): Observable<Profile[]>{
    const id = 0
    return this.http.patch<Profile[]>(this.api, {not: id})
  }

  save(data: Profile){
    return this.http.post(this.api, data)
  }

  findById(id:number): Observable<Profile>{
    return this.http.get<Profile>(`${this.api}/${id}`)
  }

  update(id: number, data: Profile){
    return this.http.put(`${this.api}/${id}`, data)
  }

  remove(id: number){
    return this.http.delete(`${this.api}/${id}`)
  }

  saveMenu(data: any): Observable<Response<Profile>>{
    return this.http.post<Response<Profile>>(`${this.apiMenu}/save-menu`, data)
  }

  findByProfile(id: number): Observable<ProfileList[]>{
    return this.http.get<ProfileList[]>(`${this.apiMenu}/profile-find/${id}`)
  }

  getMenu(): Observable<Menu[]>{
    const id = localStorage.getItem('profile')
    return this.http.get<Menu[]>(`${this.apiMenu}/profile/${id}`)
  }
}
