import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[onClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickOutSide = new EventEmitter()
  constructor(private _elementRref: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick( target ){  
    const clickedInside = this._elementRref.nativeElement.contains( target )
    //console.log(clickedInside);
    
    if( !clickedInside ){
      this.clickOutSide.emit()
    }
  }


}
