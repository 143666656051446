import { ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { LoginService } from 'src/app/pages/login/login.service';
import { ProfileService } from 'src/app/pages/profile/profile.service';
import { Menu } from './menu.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  status = 'online'
  classeStatus = 'text-success'
  menus: Menu[];
  constructor(private loginService: LoginService) { 
      
    }

  ngOnInit() {
    this.getMenus()
    this.listenLogin()
  }

  listenLogin(){
    this.loginService
          .notifier
          .subscribe(()=>{
            this.getMenus()
          })
  }

  getNome(){
    return localStorage.getItem('name')
  }

  getMenus(){
    if( localStorage.getItem('menu')){
      this.menus = JSON.parse(localStorage.getItem('menu'))
    }
    
    setTimeout(() => {
      const reload = localStorage.getItem('reload') ? Boolean(localStorage.getItem('reload')) : false
      if( !reload ){
        localStorage.setItem('reload', 'true')
        location.reload()
      }
    }, 100);
  }

}
