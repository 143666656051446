
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Commission } from 'src/app/pages/commission/commission.model';

@Component({
  selector: 'app-modal-service',
  templateUrl: './modal-service.component.html',
  styleUrls: ['./modal-service.component.css']
})
export class ModalServiceComponent implements OnInit {
  @Input() modalRef: BsModalRef
  @Input() totalAmount: number = 0
  @Input() formsReceipt: Commission[]
  @Output() option = new EventEmitter()
  @Output() closeModal  = new EventEmitter()
  msgError: string
  formGroup: FormGroup
  optionChoose: string = ""
  constructor() { }

  ngOnInit() {
    
  }  
  actionCloseModal() {
    this.closeModal.emit()
  }
  chooseOption(){
    this.option.emit(this.optionChoose)
  }



}
