import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'user',
    children: [
      {
        path: '', loadChildren: './pages/user/user.module#UserModule'
      }
    ]
  },
  {
    path: 'profile',
    children: [
      {
        path: '', loadChildren: './pages/profile/profile.module#ProfileModule'
      }
    ]
  },
  {
    path: 'menu',
    children: [
      {
        path: '', loadChildren: './pages/menu-page/menu-page.module#MenuPageModule'
      }
    ]
  },
  {
    path: 'page',
    children: [
      {
        path: '', loadChildren: './pages/page/page.module#PageModule'
      }
    ]
  },
  {
    path: 'agency',
    children: [
      {
        path: '', loadChildren: './pages/agency/agency.module#AgencyModule'
      }
    ]
  },
  {
    path: 'commission',
    children: [
      {
        path: '', loadChildren: './pages/commission/commission.module#CommissionModule'
      }
    ]
  },
  {
    path: 'concelho',
    children: [
      {
        path: '', loadChildren: './pages/concelho/concelho.module#ConcelhoModule'
      }
    ]
  },
  {
    path: 'contact',
    children: [
      {
        path: '', loadChildren: './pages/contact/contact.module#ContactModule'
      }
    ]
  },
  {
    path: 'date-entry',
    children: [
      {
        path: '', loadChildren: './pages/date-entry/date-entry.module#DateEntryModule'
      }
    ]
  },
  {
    path: 'distrito',
    children: [
      {
        path: '', loadChildren: './pages/distrito/distrito.module#DistritoModule'
      }
    ]
  },
  {
    path: 'entity',
    children: [
      {
        path: '', loadChildren: './pages/entity/entity.module#EntityModule'
      }
    ]
  },
  {
    path: 'freguesia',
    children: [
      {
        path: '', loadChildren: './pages/freguesia/freguesia.module#FreguesiaModule'
      }
    ]
  },
  {
    path: 'locality',
    children: [
      {
        path: '', loadChildren: './pages/locality/locality.module#LocalityModule'
      }
    ]
  },
  {
    path: 'payment-method',
    children: [
      {
        path: '', loadChildren: './pages/payment-method/payment-method.module#PaymentMethodModule'
      }
    ]
  },
  {
    path: 'property-type',
    children: [
      {
        path: '', loadChildren: './pages/property-type/property-type.module#PropertyTypeModule'
      }
    ]
  },
  {
    path: 'state-order',
    children: [
      {
        path: '', loadChildren: './pages/state-order/state-order.module#StateOrderModule'
      }
    ]
  },
  {
    path: 'typology',
    children: [
      {
        path: '', loadChildren: './pages/typology/typology.module#TypologyModule'
      }
    ]
  },
  {
    path: 'visit-contact',
    children: [
      {
        path: '', loadChildren: './pages/visit-contact/visit-contact.module#VisitContactModule'
      }
    ]
  },
  {
    path: 'order',
    children: [
      {
        path: '', loadChildren: './pages/order/order.module#OrderModule'
      }
    ]
  },
  {
    path: 'checking-account',
    children: [
      {
        path: '', loadChildren: './pages/list/checking-account/checking-account.module#CheckingAccountModule'
      }
    ]
  },
  {
    path: 'form-receipt',
    children: [
      {
        path: '', loadChildren: './pages/form-receipt/form-receipt.module#FormReceiptModule'
      }
    ]
  },
  {
    path: 'article',
    children: [
      {
        path: '', loadChildren: './pages/article/article.module#ArticleModule'
      }
    ]
  },
  {
    path: 'price',
    children: [
      {
        path: '', loadChildren: './pages/price/price.module#PriceModule'
      }
    ]
  },
  {
    path: 'adene',
    children: [
      {
        path: '', loadChildren: './pages/rate-adene/rate-adene.module#RateAdeneModule'
      }
    ]
  },
  { path: '**', redirectTo: 'not-found', pathMatch: 'full'},
  { path: 'not-found', component: NotFoundComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
