import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PostalCode } from './postal-code.model';
import Swal from 'sweetalert2'
import { SweetAlertModel } from 'src/app/models/sweetalert.model';
import { Order } from './order.model';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  formOrder: FormGroup //pedido
  formCustomer: FormGroup //cliente
  formProperty: FormGroup //imóvel
  formAttachment: FormGroup //Anexos
  formInvoicing: FormGroup //Faturamento
  formNotes: FormGroup //Faturamento
  api: string = environment.host
  curDate: Date =  new Date()
  datepipe: DatePipe = new DatePipe('de-DE')
  id: number
  ADMINISTRADOR: number = 2
  bugetList = []
  constructor(private http: HttpClient) {
    const profile = Number(localStorage.getItem('profile'))
    this.formOrder =  new FormGroup({
      id: new FormControl({value: null, disabled: true}),
      dateOrder: new FormControl(this.datepipe.transform(this.curDate, 'yyyy-MM-dd')),
      idStateOrder: new FormControl(1, {validators: [Validators.required]}),
      dateVisit: new FormControl({value: null, disabled: profile != this.ADMINISTRADOR}),
      hourVisit: new FormControl({value: null, disabled: profile != this.ADMINISTRADOR}),
      idAgency: new FormControl(1, {validators: [Validators.required]}),
      idPartner: new FormControl(null, {validators: [Validators.required]}),
    })
   

    this.formCustomer = new FormGroup({
      nameCustomer: new FormControl(null, {validators: [Validators.required]}),
      nifCustomer: new FormControl(null, {validators: [Validators.required]}),
      addressCustomer: new FormControl(null, {validators: [Validators.required]}),
      plotCustomer: new FormControl(null), //lote
      floorCustomer: new FormControl(null), //andar
      postalCodeCustomer1: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeCustomer2: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeCustomer: new FormControl(null, {validators: [Validators.required]}), //cp
      localityCustomer: new FormControl(null, {validators: [Validators.required]}), //cp
      idLocalityCustomer: new FormControl(null, {validators: [Validators.required]}),
      phoneCustomer: new FormControl(null),
      cellphoneCustomer: new FormControl(null, {validators: [Validators.required]}),
      emailCustomer: new FormControl(null, {validators: [Validators.required]}),
    })
    this.formProperty= new FormGroup({
      idPropertyType: new FormControl(null, {validators: [Validators.required]}),
      idTypology: new FormControl(null, {validators: [Validators.required]}),
      idDateEntry: new FormControl(1, {validators: [Validators.required]}),
      contactName: new FormControl(null,{validators: [Validators.required]}),
      contact: new FormControl(null,{validators: [Validators.required]}), 
      addressProperty: new FormControl(null,{validators: [Validators.required]}), //lote
      plot: new FormControl(null,{validators: [Validators.required]}), //lote
      floor: new FormControl(null,{validators: [Validators.required]}), //andar
      fraction: new FormControl(null,{validators: [Validators.required]}),
      postalCodeProperty1: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeProperty2: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeProperty: new FormControl(null, {validators: [Validators.required]}), //cp
      locality: new FormControl(null, {validators: [Validators.required]}), 
      idLocalityProperty: new FormControl(null, {validators: [Validators.required]}),
      distrito: new FormControl(null, {validators: [Validators.required]}),
      idDistrito: new FormControl(1, {validators: [Validators.required]}),
      concelho: new FormControl(null, {validators: [Validators.required]}),
      idConcelho: new FormControl(1, {validators: [Validators.required]}),
      freguesia: new FormControl(null, {validators: [Validators.required]}),
      idFreguesia: new FormControl(1, {validators: [Validators.required]}),
      idVisitContact: new FormControl(1, {validators: [Validators.required]}),
    })

    this.formAttachment =  new FormGroup({
      propertyBookFilename: new FormControl(null), //Caderneta predial
      propertyBook: new FormControl(null), //Caderneta predial
      contentCertificateFilename: new FormControl(null), //Certidão de teor
      contentCertificate: new FormControl(null), //Certidão de teor
      floorPlanFilename: new FormControl(null), //Planta baixa
      floorPlan: new FormControl(null), //Planta baixa
      housingLicenseFilename: new FormControl(null), //Licença de habitação
      housingLicense: new FormControl(null), //Licença de habitação
      technicalSpecificationsFilename: new FormControl(null), //ficha técnica
      technicalSpecifications: new FormControl(null), //ficha técnica
      energyBillFilename: new FormControl(null), //Fatura de eletricidade
      energyBill: new FormControl(null), //Fatura de eletricidade
      otherAttachmentOneFilename: new FormControl(null),  //Outros anexos 1
      otherAttachmentOne: new FormControl(null),  //Outros anexos 1
      otherAttachmentTwo: new FormControl(null),//Outros anexos 2
      otherAttachmentTwoFilename: new FormControl(null),//Outros anexos 2
    })
    this.formInvoicing =  new FormGroup({
      idPaymentMethod: new FormControl(1, {validators: [Validators.required]}), //Forma de pagamento
      commission: new FormControl(true),
      docs: new FormControl(null)
    })
    this.formNotes =  new FormGroup({
      observation: new FormControl(null),      
    })
  }

  setId(id: number){
    this.id = id
  }
  getId(){
    return this.id
  }

  getFormOrder(){
      return this.formOrder
  }

  getFormCustomer(){
    return this.formCustomer
  }

  getFormProperty(){
    return this.formProperty
  }

  getFormAttachment(){
    return this.formAttachment
  }

  getFormInvoicing(){
    return this.formInvoicing
  }
  getFormNotes(){
    return this.formNotes
  }

  getPostalCode(cp: string): Observable<PostalCode>{
    return this.http.get<PostalCode>(`${this.api}/cp/${cp}`)
  }

  downloadFile(file: string){
    return this.http.get(`${this.api}/download/${file}`,{responseType:'blob'})
  }

  removerValidacao(campo: string){
    
    this.formAttachment.controls[campo].clearValidators()
    this.formAttachment.controls[campo].updateValueAndValidity()
  }

  waitingDialog(title= 'Buscando pelo código informado', html = 'Fecharemos esta tela quando terminar a busca'){
    Swal.fire({
      title,
      html,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  closeDialog(){
    Swal.close()
  }

  setMessage(data: SweetAlertModel){
    return Swal.fire({
      title: data.title,
      text: data.message,
      icon: data.icon,
      html: data.html,
      confirmButtonText: 'Ok'
    })
  }

  setValueInForms(order: Order){
    const {postalCodeCustomer, postalCodeProperty} = order
    const cpCustomer = postalCodeCustomer.split('-')
    const cpProperty = postalCodeProperty.split('-')
    this.getFormOrder().controls.id.setValue(order.id)
    this.getFormOrder().patchValue(order)
    
    this.getFormCustomer().patchValue(order)
    this.getFormCustomer().controls.postalCodeCustomer1.setValue(cpCustomer[0])
    this.getFormCustomer().controls.postalCodeCustomer2.setValue(cpCustomer[1])
    this.getFormCustomer().controls.localityCustomer.setValue(order._localityCustomer.name)
    
    this.getFormProperty().patchValue(order)
    this.getFormProperty().controls.postalCodeProperty1.setValue(cpProperty[0])
    this.getFormProperty().controls.postalCodeProperty2.setValue(cpProperty[1])
    this.getFormProperty().controls.locality.setValue(order._localityProperty.name)
    this.getFormProperty().controls.distrito.setValue(order._distrito.name)
    this.getFormProperty().controls.concelho.setValue(order._concelho.name)
    this.getFormProperty().controls.freguesia.setValue(order._freguesia.name)

    this.getFormAttachment().controls.propertyBook.setValue({name: order.propertyBook, file: order.propertyBookFilename, type: order.propertyBookMimetype})
    this.getFormAttachment().controls.contentCertificate.setValue({name: order.contentCertificate, file: order.contentCertificateFilename, type: order.contentCertificateMimetype})
    this.getFormAttachment().controls.floorPlan.setValue({name: order.floorPlan, file: order.floorPlanFilename, type: order.floorPlanMimetype})
    this.getFormAttachment().controls.housingLicense.setValue({name: order.housingLicense, file: order.housingLicenseFilename, type: order.housingLicenseMimetype})
    this.getFormAttachment().controls.technicalSpecifications.setValue({name: order.technicalSpecifications, file: order.technicalSpecificationsFilename, type: order.technicalSpecificationsMimetype})
    this.getFormAttachment().controls.energyBill.setValue({name: order.energyBill, file: order.energyBillFilename, type: order.energyBillMimetype})
    this.getFormAttachment().controls.otherAttachmentOne.setValue({name: order.otherAttachmentOne, file: order.otherAttachmentOneFilename, type: order.otherAttachmentOneMimetype})
    this.getFormAttachment().controls.otherAttachmentTwo.setValue({name: order.otherAttachmentTwo, file: order.otherAttachmentTwoFilename, type: order.otherAttachmentTwoMimetype})
    
    //this.getFormInvoicing().patchValue(order)
    this.getFormInvoicing().controls.idPaymentMethod.setValue(order.idPaymentMethod)
    this.getFormInvoicing().controls.commission.setValue(order.commission)
    this.getFormInvoicing().controls.billAnotherAmount.setValue(order.billAnotherAmount)
    this.getFormInvoicing().controls.personalPropertyRecord.setValue(order.personalPropertyRecord)
    this.getFormInvoicing().controls.paymentNote.setValue({name: order.paymentNote, file: order.paymentNoteFilname, type: order.paymentNoteMimetype})
    this.getFormInvoicing().controls.invoice.setValue({name: order.invoice, file: order.invoiceFilename, type: order.invoiceMimetype})
    this.getFormInvoicing().controls.paymentVoucher.setValue({name: order.paymentVoucher, file: order.paymentVoucherFilename, type: order.paymentVoucherMimetype})
    this.getFormNotes().patchValue(order)

  }


  limparCampos(){
    this.getFormOrder().reset()
    this.getFormCustomer().reset()
    this.getFormProperty().reset()
    this.getFormAttachment().reset()
    this.getFormInvoicing().reset()
    this.getFormNotes().reset()


    this.getFormOrder().controls.dateOrder.setValue(this.datepipe.transform(this.curDate, 'yyyy-MM-dd'))
    this.getFormOrder().controls.idStateOrder.setValue(1)
    this.getFormOrder().controls.idAgency.setValue(1)
    
    
    this.getFormProperty().controls.idPropertyType.setValue(1)
    this.getFormProperty().controls.idTypology.setValue(1)
    this.getFormProperty().controls.idDateEntry.setValue(1)
    this.getFormProperty().controls.idDistrito.setValue(1)
    this.getFormProperty().controls.idConcelho.setValue(1)
    this.getFormProperty().controls.idFreguesia.setValue(1)
    this.getFormProperty().controls.idVisitContact.setValue(1)

    this.getFormAttachment().controls.propertyBookFilename.setValidators([Validators.required])

    this.getFormInvoicing().controls.idPaymentMethod.setValue(1)
    this.getFormInvoicing().controls.billAnotherAmount.setValue(null)
    this.getFormInvoicing().controls.billAnotherAmount.disable()
  }

  setValueBudget(obj: any){
    this.bugetList = [
      {description: `${obj.property_type} ${obj.name}`, value: obj.value, fixed: true},
      {description: `Taxa Adene: ${obj.name}`, value: obj.adene, fixed: true},
    ]
  }

  getBudgetList(){
    return this.bugetList
  }

  addItemBudget(obj: any){
    this.bugetList.push(obj)
  }

  removeItemBudget(idx: number){
    this.bugetList.splice(idx, 1)
  }

  
}
