import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private api = ""
  constructor(private http: HttpClient) {
    
  }

  configurarApiUrl(apiUrl: string) {
    this.api = `${environment.host}/api/${apiUrl}`
  }

  findAll(){
    return this.http.patch(this.api,{})
  }

  findAllWithTypology(user: number){
    return this.http.get(`${this.api}/typology/all/${user}`)
  }

  save(data: any){
    return this.http.post(this.api, data)
  }

  findById(id:number){
    return this.http.get(`${this.api}/${id}`)
  }

  update(id: number, data: any){
    return this.http.put(`${this.api}/${id}`, data)
  }

  bulkUpdate(data: any){
    return this.http.put(`${this.api}/multiple/update/`, data)
  }

  remove(id: number){
    return this.http.delete(`${this.api}/${id}`)
  }

  
}
